























































import { Upload } from '@/types/state';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    uploads(): Upload[] {
      return this.$store.getters['upload/list'] || [];
    },
    activeUploads(): Upload[] {
      return this.uploads.filter((u) => !u.cancelled && !u.done && !u.error && !u.hidden);
    },
    previewMax(): number {
      return this.activeUploads.reduce((sum, upload) => upload.total + sum, 0.00001);
    },
    previewValue(): number {
      return this.activeUploads.reduce((sum, upload) => upload.loaded + sum, 0.00001);
    },
  },
});
